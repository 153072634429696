<template>
  <LargeLayout>
    <div class="container">
      <div class="row body">
        <div class="col-12 login">
          <div class="card login-card forgot-pw__card">
            <div class="body login-card__body forgot-pw__card-body">
              <h1 class="text-left text-175rem">{{ $t("forgot-pw.title") }}</h1>
              <p class="text-semi-muted">
                {{ $t("forgot-pw.enter_your_mail") }}
              </p>
              <b-form @submit.prevent="onSubmit">
                <b-form-group>
                  <b-form-input
                    v-model="email"
                    type="email"
                    :placeholder="$t('forgot-pw.email')"
                    required
                    @input="hideErr"
                  />
                  <p v-if="error" class="forgot-pw__error">
                    {{ $t(`error.${error}`) }}
                  </p>
                  <b-button type="submit" variant="success" class="btn-signin">
                    {{ $t("forgot-pw.send_link") }}
                  </b-button>

                  <span class="mx-auto text-center m-0 or">{{
                    $t("forgot-pw.or")
                  }}</span>

                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-center
                      align-items-center
                    "
                  >
                    <router-link
                      :to="{ path: './register' }"
                      class="special text-decoration-underline"
                    >
                      <span class>{{
                        $t("forgot-pw.create_new_account")
                      }}</span>
                    </router-link>
                    <router-link to="/login">
                      <span class="text-decoration-underline">{{
                        $t("forgot-pw.back_to_login")
                      }}</span>
                    </router-link>
                  </div>
                </b-form-group>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LargeLayout>
</template>

<script>
import LargeLayout from "../../../layout/main/LargeLayout.vue";
import axiosConfig from "../../../libs/axiosConfig";
export default {
  name: "forgot-password",
  components: {
    LargeLayout,
  },
  data() {
    return {
      email: "",
      error: "",
      message: {
        reset: {
          type: "success",
          title: this.$t("forgot-pw.title_toast"),
          text: this.$t("forgot-pw.success_msg"),
        },
      },
    };
  },
  methods: {
    makeToast(option) {
      this.$bvToast.toast(this.message[option].text, {
        title: this.message[option].title,
        variant: this.message[option].type,
        solid: true,
        toaster: "b-toaster-top-center",
        autoHideDelay: 3000,
      });
    },
    async onSubmit() {
      await axiosConfig
        .post("/forgotPassword", {
          email: this.email,
        })
        .then((response) => {
          this.email = "";
          this.makeToast("reset");
        })
        .catch((err) => {
          this.error = err.response.data.detail;
        });
    },
    hideErr() {
      this.error = "";
    },
  },
};
</script>

<style lang="scss">
.or {
  position: relative;
}

.or::after {
  content: "";
  height: 2px;
  width: 100px;
  position: absolute;
  color: var(--success);
  display: block;
  background: var(--success);
  right: 30px;
  top: 50%;
}
.or::before {
  content: "";
  height: 2px;
  width: 100px;
  position: absolute;
  color: var(--success);
  display: block;
  background: var(--success);
  left: 30px;
  top: 50%;
  // z-index: -2;
}
.forgot-pw__card {
  .forgot-pw__card-body {
    .forgot-pw__error {
      color: var(--danger);
      margin-bottom: 0px;
    }
  }
}
</style>
